import React from "react";
import styled from "@emotion/styled";

interface SplashProps {
  image: string;
  zIndex: number;
}

const Splash = styled.div<SplashProps>`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex};
  box-sizing: border-box;
  padding: 0.8rem 1rem;
  background-image: url(${({ image }) => CSS.escape(image)});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0;
`;

interface Props extends SplashProps {}

const BrandSplash = ({ image, zIndex }: Props) => (
  <Splash className="ys-splash" image={image} zIndex={zIndex} />
);

export default BrandSplash;
