import React, { useState } from "react";
import styled from "@emotion/styled";

import {
  flashAnimFactory,
  hideWheelAnimFactory,
  slideWheelAnimFactory,
  spinAnimFactory,
  toggleAnimFactory,
} from "./animations";
import SpinButton from "./Button";
import SpinPointer from "./Pointer";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import anime from "animejs";
import { animated } from "@react-spring/web";
import soundWheelSpin from "../../audio/wheel.wav";
import { Howl } from "howler";

const OuterWrapper = styled(animated.div)`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 360px;
  overflow: hidden;
`;

const InnerWrapper = styled.div`
  position: relative;
  width: 539px;
  height: 539px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Pointer = styled(SpinPointer)`
  position: absolute;
  top: -20px;
  left: 237.5px;
  z-index: 20;
`;

const Button = styled(SpinButton)`
  position: absolute;
  top: 225.5px;
  z-index: 20 !important;
`;

const Segments = styled(animated.svg)`
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 10;
`;

const Background = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

interface Props {
  isPlayed?: boolean;
  className?: string;
  isWheelSpinning: boolean;
  onSpinFinished: () => void;
}

const Wheel = ({
  isPlayed,
  className,
  onSpinFinished,
  isWheelSpinning,
}: Props) => {
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;

  const [flashAnimation, setFlashAnimation] = useState<
    ReturnType<typeof anime> | undefined
  >();
  const [spinAnimation, setSpinAnimation] = useState<
    ReturnType<typeof anime> | undefined
  >();
  const [toggleAnimation, setToggleAnimation] = useState<
    ReturnType<typeof anime> | undefined
  >();
  const [slideWheelAnimation, setSlideWheelAnimation] = useState<
    ReturnType<typeof anime> | undefined
  >();

  React.useEffect(() => {
    const _spinAnimation = spinAnimFactory();
    _spinAnimation.finished.then(() => {
      const hideWheelAnim = hideWheelAnimFactory();
      hideWheelAnim.play();
      hideWheelAnim.finished.then(() => {
        onSpinFinished();
      });
    });
    setSpinAnimation(_spinAnimation);
    setFlashAnimation(flashAnimFactory());
    setToggleAnimation(toggleAnimFactory());
    setSlideWheelAnimation(slideWheelAnimFactory());
  }, []);

  // const randomTruthy = anime.random(0, 1) % 2;
  // const spin = -360 * 9;
  // const rotate = randomTruthy ? spin : spin - 30;
  // const SPIN_DURATION = 10400;

  // const [spring, api] = useSpring(() => ({
  //   from: {
  //     rotate: 0,
  //     transform: `rotateZ(0deg)`
  //   },
  //   config: {
  //     easing: easings.easeOutSine,
  //     duration: SPIN_DURATION,
  //   }
  // }));

  if (isPlayed) {
    return null;
  }

  if (flashAnimation && !flashAnimation.began) {
    flashAnimation.play();
  }

  if (isWheelSpinning && !spinAnimation?.began) {
    // api.start({
    //     to: {
    //       rotate: rotate,
    //       transform: `rotateZ(${rotate}deg)`
    //     },
    //   }
    // );
    spinAnimation?.play();
    toggleAnimation?.play();
    slideWheelAnimation?.play();
    new Howl({ src: [soundWheelSpin] }).play();
  }

  return (
    <OuterWrapper id="ys-spin-wheel-container">
      <InnerWrapper className={className}>
        <Pointer />
        <Button />
        <Segments
          style={{ willChange: "transform" }}
          id="ys-wheel"
          width="527"
          height="527"
          viewBox="0 0 527 527"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M263.5 526C408.475 526 526 408.475 526 263.5C526 118.525 408.475 0.999756 263.5 0.999756C118.525 0.999756 0.999878 118.525 0.999878 263.5C0.999878 408.475 118.525 526 263.5 526Z"
            fill="white"
          />
          <path
            className="ys-wheel-segment ys-wheel-segment-blue"
            d="M80.4956 78.1092C111.864 47.1425 151.049 24.0802 194.821 12.148L260.223 257.407L80.4956 78.1092Z"
            fill={branding.wheelSecondaryColor}
            stroke="white"
            strokeWidth="4"
          />
          <path
            className="ys-wheel-segment ys-wheel-segment-pink"
            d="M264.069 264.069L77.6617 78.1072C45.6523 110.193 21.899 150.519 9.85895 195.627L264.069 264.069Z"
            fill={branding.wheelMainColor}
          />
          <path
            className="ys-wheel-segment ys-wheel-segment-blue"
            d="M2.99994 263.5C2.99994 240.904 5.87633 218.983 11.2819 198.082L256.376 264.069L11.5595 329.982C5.97485 308.761 2.99994 286.479 2.99994 263.5Z"
            fill={branding.wheelSecondaryColor}
            stroke="white"
            strokeWidth="4"
          />
          <path
            className="ys-wheel-segment ys-wheel-segment-pink"
            d="M264.069 264.069L10.1448 332.434C22.345 377.38 46.1865 417.536 78.2319 449.462L264.069 264.069Z"
            fill={branding.wheelMainColor}
          />
          <path
            className="ys-wheel-segment ys-wheel-segment-blue"
            d="M195.104 514.929C151.485 503.091 112.409 480.204 81.0701 449.456L260.223 270.731L195.104 514.929Z"
            fill={branding.wheelSecondaryColor}
            stroke="white"
            strokeWidth="4"
          />
          <path
            className="ys-wheel-segment ys-wheel-segment-pink"
            d="M264.069 264.069L196.521 517.377C217.903 523.004 240.351 526 263.5 526C287.033 526 309.842 522.903 331.543 517.095L264.069 264.069Z"
            fill={branding.wheelMainColor}
          />
          <path
            className="ys-wheel-segment ys-wheel-segment-blue"
            d="M267.916 270.731L446.503 448.892C415.321 479.674 376.415 502.646 332.958 514.638L267.916 270.731Z"
            fill={branding.wheelSecondaryColor}
            stroke="white"
            strokeWidth="4"
          />
          <path
            className="ys-wheel-segment ys-wheel-segment-pink"
            d="M264.069 264.069L449.337 448.894C481.163 416.992 504.828 376.944 516.932 332.148L264.069 264.069Z"
            fill={branding.wheelMainColor}
          />
          <path
            className="ys-wheel-segment ys-wheel-segment-blue"
            d="M271.762 264.069L515.792 198.368C521.15 219.184 524 241.007 524 263.5C524 286.376 521.052 308.56 515.516 329.695L271.762 264.069Z"
            fill={branding.wheelSecondaryColor}
            stroke="white"
            strokeWidth="4"
          />
          <path
            className="ys-wheel-segment ys-wheel-segment-pink"
            d="M517.217 195.914C505.173 150.585 481.305 110.074 449.115 77.8843C449.379 78.1481 449.643 78.4126 449.905 78.6776L264.069 264.069L517.217 195.914Z"
            fill={branding.wheelMainColor}
          />
          <path
            className="ys-wheel-segment ys-wheel-segment-blue"
            d="M333.24 12.4397C376.85 24.5274 415.866 47.6747 447.076 78.6753L267.916 257.407L333.24 12.4397Z"
            fill={branding.wheelSecondaryColor}
            stroke="white"
            strokeWidth="4"
          />
          <path
            className="ys-wheel-segment ys-wheel-segment-pink"
            d="M264.069 264.069L331.826 9.98046C310.041 4.12344 287.135 0.999756 263.5 0.999756C239.998 0.999756 217.218 4.08826 195.542 9.88152C195.774 9.81969 196.005 9.75823 196.237 9.69702L264.069 264.069Z"
            fill={branding.wheelMainColor}
          />
          <path
            d="M521.5 263.5C521.5 405.989 405.989 521.5 263.5 521.5C121.01 521.5 5.49988 405.989 5.49988 263.5C5.49988 121.01 121.01 5.49976 263.5 5.49976C405.989 5.49976 521.5 121.01 521.5 263.5ZM263.5 526.5C408.751 526.5 526.5 408.751 526.5 263.5C526.5 118.249 408.751 0.499756 263.5 0.499756C118.249 0.499756 0.499878 118.249 0.499878 263.5C0.499878 408.751 118.249 526.5 263.5 526.5Z"
            fill="white"
            stroke="white"
          />
        </Segments>
        <Background
          width="539"
          height="539"
          viewBox="0 0 539 539"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_60_179)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M269.5 532C414.475 532 532 414.475 532 269.5C532 124.525 414.475 6.99976 269.5 6.99976C124.525 6.99976 6.99988 124.525 6.99988 269.5C6.99988 414.475 124.525 532 269.5 532Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_60_179"
              x="-0.00012207"
              y="-0.000244141"
              width="539"
              height="539"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="3.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_60_179"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_60_179"
                result="shape"
              />
            </filter>
          </defs>
        </Background>
      </InnerWrapper>
    </OuterWrapper>
  );
};

export default Wheel;
