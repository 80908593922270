import styled from "@emotion/styled";
import React, { useContext } from "react";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import {
  VenueConfigContext,
  VenueConfigContextType,
} from "../../contexts/VenueConfigContext";

const StyledContainer = styled.div`
  font-size: 0.75rem;
  text-align: center;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  font-family: "Barlow Semi Condensed", sans-serif;
`;

const TermsAndConditions = () => {
  const { theme: branding } = useContext(
    BrandingContext
  ) as BrandingContextType;
  const { config: venueConfig } = useContext(
    VenueConfigContext
  ) as VenueConfigContextType;

  return (
    <StyledContainer style={{ color: branding.backgroundTextColor }}>
      By pressing PLAY, I accept the {` `}
      <br />
      <a
        href={venueConfig.termsAndConditionsLink}
        target="_blank"
        rel="noreferrer"
        style={{ color: branding.backgroundTextColor }}
      >
        Terms & Conditions
      </a>
    </StyledContainer>
  );
};

export default TermsAndConditions;
