import React, { useEffect, useState } from "react";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import { resultsAnim } from "./animations";
import StyledText from "../Text/StyledText";

const QuizRoundTimerExpired = () => {
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;

  const [animationCompleted, setAnimationCompleted] = useState<boolean>(false);
  useEffect(() => {
    if (!animationCompleted) {
      resultsAnim();
      setAnimationCompleted(true);
    }
  }, []);

  return (
    <>
      <StyledText style={{ color: branding.backgroundTextColor }}>
        Better luck next time!
      </StyledText>
    </>
  );
};

export default QuizRoundTimerExpired;
