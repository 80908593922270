import React, { useEffect } from "react";
import styled from "@emotion/styled";
import Win from "./Win";
import NoWin from "./NoWin";
import TriviaQuestion from "./TriviaQuestion";
import ConversationStarter from "./ConversationStarter";
import EdinburghFact from "./EdinburghFact";
import EdinburghRecommendation from "./EdinburghRecommendation";
import Joke from "./Joke";
import QuizRound from "./QuizRound";
import QuizRoundTimerExpired from "./QuizRoundTimerExpired";

const Wrapper = styled.div`
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 2rem;
  margin-top: 328px;
  text-align: center;
  opacity: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

interface Props {
  ticket: Ticket;
  showResult: boolean;
  onInteractionFinished?: () => void;
  onConfettiStart?: () => void;
  onConfettiStop?: () => void;
}

const Result = ({
  ticket,
  showResult,
  onInteractionFinished,
  onConfettiStart,
  onConfettiStop,
}: Props) => {
  useEffect(() => {
    if (
      [
        "no-win",
        "win-prize",
        "quiz-round-no-win",
        "quiz-round-win-prize",
        "quiz-round-expired",
      ].includes(ticket?.outcome)
    ) {
      onInteractionFinished && onInteractionFinished();
    }
  }, [ticket, onInteractionFinished]);

  if (!showResult) {
    return null;
  }

  let result: React.ReactNode;

  switch (ticket?.outcome) {
    case "no-win":
      result = <NoWin />;
      break;
    case "win-trivia-question":
      result = <TriviaQuestion />;
      break;
    case "win-conversation-starter":
      result = <ConversationStarter />;
      break;
    case "edinburgh-fact":
      result = <EdinburghFact />;
      break;
    case "edinburgh-recommendation":
      result = <EdinburghRecommendation />;
      break;
    case "a-joke":
      result = <Joke />;
      break;
    case "win-prize":
    case "quiz-round-win-prize":
      result = (
        <Win
          ticket={ticket}
          onConfettiStart={onConfettiStart}
          onConfettiStop={onConfettiStop}
        />
      );
      break;
    case "quiz-round":
      result = <QuizRound ticket={ticket} onFinished={onInteractionFinished} />;
      break;
    case "quiz-round-expired":
      result = <QuizRoundTimerExpired />;
      break;
    default:
      result = <NoWin />;
      break;
  }

  return <Wrapper className="ys-results">{result}</Wrapper>;
};

export default Result;
