import React from "react";
import styled from "@emotion/styled";

interface Props {
  text: string;
  color?: string;
  borderColor?: string;
  fontWeight?: string;
  fontSize?: string;
  textTransform?: string;
  expand?: boolean;
}

const StyledLabelText = styled.div<Props>`
  color: ${(props) => props.color};
  border: ${(props) => `2px solid ${props.borderColor}`};
  text-transform: ${(props) => props.textTransform};
  padding: ${(props) => `0.75rem ${props.expand ? "0" : "1.5rem"}`};
  font-size: ${(props) => props.fontSize};
  font-weight: 900;
  width: ${(props) => (props.expand ? "100%" : "auto")};
`;

const LabelText = ({
  text,
  color = "white",
  borderColor,
  textTransform = "uppercase",
  fontSize = "18px",
  expand = false,
}: Props) => (
  <StyledLabelText
    text=""
    textTransform={textTransform}
    color={color}
    borderColor={borderColor}
    fontSize={fontSize}
    expand={expand}
  >
    {text}
  </StyledLabelText>
);

export default LabelText;
