import anime from "animejs";

export const resultsAnim = () => {
  anime({
    targets: ".ys-results",
    scale: [0.3, 1],
    opacity: [0, 1],
    easing: "easeOutElastic",
  });

  anime({
    targets: ".ys-results",
    opacity: [0, 1],
    easing: "easeOutSine",
  });
};
