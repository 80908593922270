import React, { useState } from "react";
import anime from "animejs";
import styled from "@emotion/styled";
import Prize from "./Prize";
import TicketPrize from "./TicketPrize";
import {
  carouselFastAnimFactory,
  carouselSlowAnimFactory,
  prizeAnimFactory,
} from "./animations";

const PRIZE_COUNT = 9;

const randomisePrizes = (prizes: Prize[]): Prize[] => {
  const array: Prize[] = [];
  for (let n = 0; n < PRIZE_COUNT; n++) {
    array.push(prizes[n % prizes.length]);
  }
  // https://stackoverflow.com/a/12646864
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const Wrapper = styled.div`
  position: absolute;
  top: calc(72px + 3rem);
  left: 0;
  width: 100%;
`;

interface Props {
  ticket: Ticket;
  prizes: Prize[];
  isWheelSpinning: boolean;
  showResult: boolean;
  playSounds: boolean;
}

const Carousel = ({
  ticket,
  prizes,
  isWheelSpinning,
  showResult,
  playSounds,
}: Props) => {
  const [fastCarouselAnimation, setFastCarouselAnimation] = useState<
    ReturnType<typeof anime> | undefined
  >();
  const [prizeAnimation, setPrizeAnimation] = useState<
    ReturnType<typeof anime> | undefined
  >();

  React.useEffect(() => {
    const _fastCarouselAnimation = carouselFastAnimFactory();
    _fastCarouselAnimation.finished.then(() => {
      const carouselSlowAnim = carouselSlowAnimFactory();
      carouselSlowAnim.finished.then(() => {
        const prizeAnim = prizeAnimFactory();
        prizeAnim.play();
      });
      carouselSlowAnim.play();
    });
    setFastCarouselAnimation(_fastCarouselAnimation);

    setPrizeAnimation(prizeAnimFactory());
  }, []);

  if (showResult && prizeAnimation && !prizeAnimation.began) {
    prizeAnimation.seek(800);
  }

  if (
    isWheelSpinning &&
    fastCarouselAnimation &&
    !fastCarouselAnimation.began
  ) {
    fastCarouselAnimation.play();
  }

  return (
    <Wrapper>
      {randomisePrizes(prizes).map((prize: Prize, index: number) => {
        return <Prize key={index} image={prize?.image} />;
      })}
      <TicketPrize
        image={ticket.image}
        isPrizeWon={
          ticket.outcome === "win-prize" ||
          ticket.outcome === "quiz-round-win-prize"
        }
        showPrizeEffects={
          ticket.outcome !== "quiz-round" && showResult && playSounds
        }
      />
    </Wrapper>
  );
};

export default Carousel;
