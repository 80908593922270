import React from "react";
import styled from "@emotion/styled";
import Logo from "../Logo/Logo";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-bottom: 100px;
  }
`;

const Home = () => (
  <Wrapper>
    <Logo display3d width="204" height="102" />
  </Wrapper>
);

export default Home;
