import styled from "@emotion/styled";

const StyledYSInput = styled.input<{
  textColor: string;
  placeholderTextColor: string;
}>`
  color: ${(props) => props.textColor};
  font-size: 2rem;
  text-align: center;
  outline: none;
  border: ${(props) => `1px solid ${props.textColor}`};
  border-radius: 2px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  width: 50%;

  ::placeholder {
    color: ${(props) => props.placeholderTextColor};
    opacity: 0.6;
  }
`;

export default StyledYSInput;
