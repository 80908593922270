import React, { useEffect, useMemo, useState } from "react";

import TextualWinBase from "./TextualWinBase";
import { resultsAnim } from "./animations";
import { facts } from "./edinburghFacts";

const EdinburghFact = () => {
  const [animationCompleted, setAnimationCompleted] = useState(false);
  useEffect(() => {
    if (!animationCompleted) {
      resultsAnim();
      setAnimationCompleted(true);
    }
  });

  const fact = useMemo(() => {
    const selectedIndex = Math.floor(Math.random() * facts.length);
    return facts[selectedIndex];
  }, []);

  return <TextualWinBase headingText={fact} />;
};

export default EdinburghFact;
