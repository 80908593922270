import React, { useContext, useEffect } from "react";
import styled from "@emotion/styled";
import VenueSplash from "./VenueSplash";
import BrandSplash from "./BrandSplash";
import { splashAnimFactory } from "./animations";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  pointer-events: none;
`;

export const FADE2APP_EVENT = "launchfinished";

interface LaunchProps {
  onLaunchAnimationsFinished: () => void;
}

const Launch = ({ onLaunchAnimationsFinished }: LaunchProps) => {
  const { theme } = useContext(BrandingContext) as BrandingContextType;

  useEffect(() => {
    if (theme.venueSplashImage || theme.campaignSplashImage) {
      let splashScreens = 0;
      theme.campaignSplashImage && splashScreens++;
      theme.venueSplashImage && splashScreens++;
      splashAnimFactory(splashScreens, onLaunchAnimationsFinished);
    }
  }, [theme.venueSplashImage, theme.campaignSplashImage]);

  return (
    <Wrapper id="ys-launch" style={{ backgroundColor: theme.backgroundColor }}>
      {theme.venueSplashImage && <VenueSplash image={theme.venueSplashImage} />}
      {theme.campaignSplashImage && (
        <BrandSplash zIndex={500} image={theme.campaignSplashImage} />
      )}
    </Wrapper>
  );
};

export default Launch;
