import styled from "@emotion/styled";
import React, { useContext, useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { resultsAnim } from "./animations";
import QuizRoundIntro from "./QuizRoundIntro";
import QuizRoundQuestion from "./QuizRoundQuestion";
import TextualWinBase from "./TextualWinBase";
import { useMutation, useQueryClient } from "react-query";
import { playQuizRound, submitQuizRoundAnswer } from "../../API";
import { GameRouteParams } from "../Game/Game";
import { useParams } from "react-router-dom";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";

const StyledTimer = styled.div`
  font-family: "Fira Mono", monospace;
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: #ad393a;
  width: 100%;
  text-align: center;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
`;

interface Props {
  ticket: Ticket;
  onFinished?: () => void;
}

const QuizRound = ({ ticket, onFinished }: Props) => {
  const { venueShortName } = useParams() as GameRouteParams;
  const { theme } = useContext(BrandingContext) as BrandingContextType;

  const [animationCompleted, setAnimationCompleted] = useState<boolean>(false);
  useEffect(() => {
    if (!animationCompleted) {
      resultsAnim();
      setAnimationCompleted(true);
    }
  }, []);

  const expiryDate = new Date();
  expiryDate.setSeconds(expiryDate.getSeconds() + 30);
  const [isTimerExpired, setIsTimerExpired] = useState<boolean>(false);

  const {
    hours,
    minutes,
    seconds,
    isRunning: isTimerRunning,
    start: startTimer,
  } = useTimer({
    autoStart: false,
    expiryTimestamp: expiryDate,
    onExpire: () => {
      // @ts-ignore
      queryClient.setQueryData<Ticket>(["ticket", ticket.id], (ticket) => {
        if (ticket) {
          return {
            ...ticket,
            outcome: "quiz-round-expired",
            image: theme.noPrizeImage,
          };
        }
        return undefined;
      });
      setIsTimerExpired(true);
      onFinished && onFinished();
    },
  });

  function getTimerDisplayText(): string {
    return `${hours.toLocaleString("en-GB", {
      minimumIntegerDigits: 2,
    })}:${minutes.toLocaleString("en-GB", {
      minimumIntegerDigits: 2,
    })}:${seconds.toLocaleString("en-GB", { minimumIntegerDigits: 2 })}`;
  }

  const playQuizRoundMutation = useMutation({
    mutationFn: playQuizRound,
  });

  const queryClient = useQueryClient();
  const submitQuizRoundAnswerMutation = useMutation({
    mutationFn: submitQuizRoundAnswer,
    onSuccess: (ticket) => {
      queryClient.invalidateQueries({ queryKey: ["ticket", ticket.id] });
    },
  });

  const onQuizRoundQuestionAnswered = (answer: string) => {
    submitQuizRoundAnswerMutation.mutate({
      venueShortName,
      ticketId: ticket.id,
      answer: answer,
    });
  };

  return (
    <>
      <div style={{ flexGrow: 1 }}></div>
      <StyledTimer>{getTimerDisplayText()}</StyledTimer>
      {isTimerExpired ? (
        <TextualWinBase
          headingText="Timer has ran out </3"
          subheadingText="Better luck next time!"
        />
      ) : (
        <>
          {isTimerRunning ? (
            <QuizRoundQuestion
              question={ticket.quizRoundQuestion || "unexpected"}
              onAnswered={(answer) => {
                onQuizRoundQuestionAnswered(answer);
              }}
            />
          ) : (
            <QuizRoundIntro
              onPressedPlay={() => {
                if (!isTimerRunning) {
                  startTimer();
                  playQuizRoundMutation.mutate({
                    venueShortName,
                    ticketId: ticket.id,
                  });
                }
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default QuizRound;
