export const starters: string[] = [
  "What's your most embarrassing, yet funny story? ",
  "Would you rather be a genius and know everything or be amazing at any activity you tried?",
  "Would you rather get given one million pounds no strings attached, or flip a coin for one billion pounds?",
  "What is the weirdest thing you are afraid of?",
  "What is something that across the board, everyone looks stupid doing?",
  "What would be the absolute worst name that you could give your child?",
  "What movie completely changes its plot when you change one letter in its title?",
  "What's the weirdest thing you've ever eaten?",
  "What is one of the most embarrassing phases you went through?",
  "Who on your table is most likely to survive a zombie apocalypse?",
  "If you had to change your name to something completely new, what would be your new name?",
  "Worst date you've ever been on?",
  "Best movie / TV show you have seen recently?",
  "What topic could you give a 30-minute presentation on without any preparation?",
  "What is your most bizarre pet peeve?",
  "Who on your table would be the worst person to be stuck in an elevator with?",
  "What is one thing that is considered socially acceptable, but really shouldn't be?",
  "What is the most ridiculous fact you know?",
  "What's everyone's worst purchase been?",
  "What scene in a movie always makes you laugh every time you watch it?",
  "Worst gift you have ever received? ",
  "What's the weirdest thing that a guest has done at your house?",
  "Would you rather go into the past and meet your ancestors or go into the future and meet your great-great-grandchildren?",
  "Would you rather have more money or more time?",
  "Would you rather be without elbows or knees?",
  "Would you rather your only mode of transportation be a donkey or a giraffe?",
  "Would you rather lose the ability to lie or believe everything you're told?",
  "Would you rather lose all of the money you've earned this year or lose all of the memories you've gained this year?",
  "Would you rather know the world's secrets or live ignorantly forever?",
  "Would you rather have a rewind button or a pause button on your life?",
  "Would you rather be the smartest person or the funniest person?",
  "Would you rather have the only beverage you can drink be water or the only food you can eat be a salad?",
];
