import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { get, set } from "local-storage";
import { createTicketSession, StartData, startSession } from "../../API";
import Wait from "../Wait/Wait";
import { useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";

type StartParams = {
  venueShortName: string;
  cardId: string;
};

const Start = () => {
  const { venueShortName, cardId } = useParams() as StartParams;
  const navigate = useNavigate();
  const defaultWaitMessage =
    "You have reached the spin limit... please try again later";
  const [isPlayingBlocked, setIsPlayingBlocked] = useState(false);

  const { error, data } = useQuery<StartData, AxiosError>(
    ["startData", venueShortName, cardId],
    () => startSession(venueShortName, cardId),
    {
      retry: 1,
    }
  );

  const createTicketMutation = useMutation({
    mutationFn: createTicketSession,
    onSuccess: (data) => {
      previousPlaysJSON.push(new Date().toString());
      set<string>(cardId!, JSON.stringify(previousPlaysJSON));
      navigate(`/${venueShortName}/${data.ticketId}`, { replace: true });
    },
  });

  const previousPlaysString = get<string | null>(cardId || "") || "[]";
  let previousPlaysJSON: any;

  try {
    previousPlaysJSON = JSON.parse(previousPlaysString);
  } catch (e) {
    previousPlaysJSON = [];
  }

  useEffect(() => {
    if (!data) return;

    if (data?.playRestrictionTimePeriod && data?.playRestrictionSpinLimit) {
      const currentTime = new Date().getTime();
      const playsInLastPeriod = previousPlaysJSON.filter((playDate: string) => {
        const playTime = new Date(playDate).getTime();
        const secondsSincePlay = (currentTime - playTime) / 1000;
        return secondsSincePlay < data.playRestrictionTimePeriod! * 60;
      });
      previousPlaysJSON = playsInLastPeriod;
      if (previousPlaysJSON.length >= data.playRestrictionSpinLimit) {
        setIsPlayingBlocked(true);
        return;
      }
      !createTicketMutation.isLoading &&
        createTicketMutation.mutate({ venueShortName, cardId });
    } else {
      !createTicketMutation.isLoading &&
        createTicketMutation.mutate({ venueShortName, cardId });
    }
  }, [data]);

  if (createTicketMutation.isError) {
    return (
      <Wait message="Uable to start a play session... please try again later" />
    );
  }

  if (error) {
    const msg =
      error.response?.status === 404
        ? "Expired Activation or Invalid QR Code."
        : error.message;
    return <Wait message={msg} />;
  }

  return isPlayingBlocked ? (
    <Wait message={data?.playRestrictionMessage || defaultWaitMessage} />
  ) : (
    <></>
  );
};

export default Start;
