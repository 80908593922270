import { createContext, useState } from "react";
import { VenueDto } from "../API";

export type VenueConfig = {
  venueId?: number;
  venueName?: string;

  termsAndConditionsLink: string;

  playerAgeCollectionEnabled: boolean;
  playerAgeMinRequired?: number;

  playRestrictionTimePeriod?: number;
  playRestrictionSpinLimit?: number;
  playRestrictionMessage: string;

  surveyQuestion?: string;
  surveyPossibleAnswers?: string[];
};

export const defaultVenueConfig = {
  termsAndConditionsLink: "https://www.your-spin.com/terms-and-conditions",
  playerAgeCollectionEnabled: false,
  playRestrictionMessage:
    "You have reached the spin limit... please try again later",
};

export type VenueConfigContextType = {
  config: VenueConfig;
  updateConfig: (data: VenueDto) => void;
};

export const VenueConfigContext = createContext<
  VenueConfigContextType | undefined
>(undefined);

export const VenueConfigProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [config, setConfig] = useState<VenueConfig>(defaultVenueConfig);

  const updateConfig = (data: VenueDto) => {
    setConfig({
      venueId: data.venue.id,
      venueName: data.venue.name,
      termsAndConditionsLink:
        data.campaign.termsAndConditionsLink ||
        data.venue.termsAndConditionsLink ||
        defaultVenueConfig.termsAndConditionsLink,
      playerAgeCollectionEnabled:
        data.campaign.playerAgeCollectionEnabled ||
        defaultVenueConfig.playerAgeCollectionEnabled,
      playerAgeMinRequired: data.campaign.playerAgeMinRequired || undefined,
      playRestrictionTimePeriod:
        data.campaign.playRestrictionTimePeriod || undefined,
      playRestrictionSpinLimit:
        data.campaign.playRestrictionSpinLimit || undefined,
      playRestrictionMessage:
        data.campaign.playRestrictionMessage ||
        defaultVenueConfig.playRestrictionMessage,
      surveyQuestion: data.campaign.surveyQuestion || undefined,
      surveyPossibleAnswers: data.campaign.surveyPossibleAnswers || undefined,
    });
  };

  return (
    <VenueConfigContext.Provider value={{ config, updateConfig }}>
      {children}
    </VenueConfigContext.Provider>
  );
};

export default VenueConfigProvider;
