import React, { useEffect, useState } from "react";

import { resultsAnim } from "./animations";
import LabelText from "./LabelText";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";

interface Props {
  headingText: string;
  subheadingText?: string;
}

const TextualWinBase = ({ headingText, subheadingText = "" }: Props) => {
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;

  const [animationCompleted, setAnimationCompleted] = useState(false);
  useEffect(() => {
    if (!animationCompleted) {
      resultsAnim();
      setAnimationCompleted(true);
    }
  });

  return (
    <>
      <div style={{ marginBottom: "1.5rem" }}>
        <LabelText
          text={headingText}
          color={branding.wheelMainTextColor}
          borderColor={branding.wheelMainColor}
        />
      </div>
      <div style={{ flexGrow: 1 }} />
      {subheadingText?.length > 0 && (
        <div style={{ marginBottom: "1rem" }}>
          <LabelText
            text={subheadingText}
            color={branding.wheelSecondaryTextColor}
            borderColor={branding.wheelSecondaryColor}
            textTransform="none"
            fontSize="14px"
          />
        </div>
      )}
    </>
  );
};

export default TextualWinBase;
