import React, { useContext, useEffect, useState } from "react";

import { resultsAnim } from "./animations";
import StyledText from "../Text/StyledText";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";

const NoWin = () => {
  const { theme: branding } = useContext(
    BrandingContext
  ) as BrandingContextType;

  const [animationCompleted, setAnimationCompleted] = useState(false);
  useEffect(() => {
    if (!animationCompleted) {
      resultsAnim();
      setAnimationCompleted(true);
    }
  });

  return (
    <div>
      <StyledText style={{ color: branding.backgroundTextColor }}>
        Better luck next time!
      </StyledText>
    </div>
  );
};

export default NoWin;
