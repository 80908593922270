import Title from "../../components/Title/Title";
import Carousel from "../../components/Prize/Carousel";
import Wheel from "../../components/Wheel/Wheel";
import { player_age_key } from "../../components/PreGame/CaptureAge";
import Result from "../../components/Result/Result";
import { get, set } from "local-storage";
import { v4 as uuid } from "uuid";
import styled from "@emotion/styled";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import { playTicket, Ticket } from "../../API";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { GameRouteParams } from "../../components/Game/Game";
import Confetti from "react-confetti";

const getDeviceId = (): string => {
  const key = "ys-device-id";
  const deviceId = get<string>(key);
  if (deviceId) return deviceId;
  const newDeviceId = uuid();
  set<string>(key, newDeviceId);
  return newDeviceId;
};

const Game = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

interface SpinTheWheelParams {
  ticket: Ticket;
  prizes: Prize[];
  isPlayable: boolean;
  onGameFinished: () => void;
}

const SpinTheWheel = ({
  ticket,
  prizes,
  isPlayable,
  onGameFinished,
}: SpinTheWheelParams) => {
  const { venueShortName } = useParams() as GameRouteParams;
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;

  const [isWheelSpinning, setIsWheelSpinning] = useState<boolean>(false);
  const [isResultInteractionFinished, setIsResultInteractionFinished] =
    useState<boolean>(false);
  const [isWheelSpinFinished, setIsWheelSpinFinished] =
    useState<boolean>(false);
  const [isTicketPlayed] = useState(ticket.playedAt !== null);

  const queryClient = useQueryClient();
  const playTicketMutation = useMutation({
    mutationFn: playTicket,
    onSuccess: () => {
      setIsWheelSpinning(true);
      queryClient.invalidateQueries({ queryKey: ["ticket", ticket.id] });
    },
  });

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (
      (isTicketPlayed && ticket.outcome !== "quiz-round") ||
      (isWheelSpinFinished && isResultInteractionFinished)
    ) {
      onGameFinished && onGameFinished();
    }
  }, [isWheelSpinFinished, isResultInteractionFinished]);

  const [showConfetti, setShowConfetti] = useState(false);
  const [loopConfetti, setLoopConfetti] = useState(false);

  const onGamePress = () => {
    if (!isTicketPlayed && !isWheelSpinning) {
      setIsWheelSpinning(true);
      playTicketMutation.mutate({
        venueShortName,
        ticketId: ticket.id,
        deviceId: getDeviceId(),
        ...(get<number>(player_age_key) && {
          playerAge: get<number>(player_age_key),
        }),
      });
    }
  };

  return (
    <Game
      style={{ backgroundColor: branding.backgroundColor }}
      ref={ref}
      onClick={onGamePress}
    >
      {showConfetti && (
        <Confetti
          {...(ref?.current?.clientHeight && {
            height: ref.current.clientHeight,
          })}
          recycle={loopConfetti}
          initialVelocityX={2}
          friction={0.99}
          initialVelocityY={50}
          gravity={0.1}
          numberOfPieces={400}
          onConfettiComplete={() => {
            if (!loopConfetti) {
              setShowConfetti(false);
              setLoopConfetti(false);
            }
          }}
        />
      )}
      <Title
        ticket={ticket}
        isWheelSpinning={isWheelSpinning}
        showResult={isTicketPlayed || isWheelSpinFinished}
      />
      <Carousel
        ticket={ticket}
        prizes={prizes}
        isWheelSpinning={isWheelSpinning}
        showResult={isTicketPlayed || isWheelSpinFinished}
        playSounds={isPlayable}
      />
      <Wheel
        isPlayed={isTicketPlayed}
        isWheelSpinning={isWheelSpinning}
        onSpinFinished={() => {
          setIsWheelSpinFinished(true);
        }}
      />
      <Result
        ticket={ticket}
        showResult={isTicketPlayed || isWheelSpinFinished}
        onInteractionFinished={() => {
          setIsResultInteractionFinished(true);
        }}
        onConfettiStart={() => {
          setLoopConfetti(true);
          setShowConfetti(true);
        }}
        onConfettiStop={() => {
          setLoopConfetti(false);
        }}
      />
    </Game>
  );
};

export default SpinTheWheel;
