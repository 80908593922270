import React from "react";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import StyledText from "../Text/StyledText";
import YSButton from "../YSButton";

type QuizRoundIntroProps = {
  onPressedPlay: () => void;
};

const QuizRoundIntro = ({ onPressedPlay }: QuizRoundIntroProps) => {
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;

  return (
    <>
      <StyledText style={{ color: branding.backgroundTextColor }}>
        Answer the question before the timer runs out to view the mystery prize
      </StyledText>
      <br />
      <br />
      <YSButton
        text="Tap to play"
        expandWidth={true}
        onPressed={onPressedPlay}
      />
    </>
  );
};

export default QuizRoundIntro;
