import React from "react";
import styled from "@emotion/styled";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";

interface Props {
  text: string;
}

const StyledCalloutText = styled.div`
  font-size: 32px;
  margin: 0 0;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
`;

const CalloutText = ({ text }: Props) => {
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;

  return (
    <StyledCalloutText
      style={{
        color: branding.wheelMainColor,
      }}
    >
      {text}
    </StyledCalloutText>
  );
};

export default CalloutText;
