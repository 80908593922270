export const jokes: string[] = [
  "Before the crowbar was invented, crows drank at home.",
  'A tennis ball walks into a bar. The barman says, "have you been served?".',
  "How fast is milk? Its pasteurized before you know it.",
  "What's the best thing about Switzerland? I don't know but the flag is a big plus.",
  "Why don't scientists trust atoms? Because they make up everything.",
  "How do you keep a bagel from getting away? Put lox on it.",
  "What did the left eye say to the right eye? Between you and me, something smells.",
  "How do you make a tissue dance? Put a little boogie in it.",
  "What do you call a pony with a cough? A little horse.",
  "What did the shark say when he ate the clown fish? This tastes a little funny.",
  "Why can't you hear a pterodactyl go to the bathroom? Because the 'P' is silent.",
  "Why did the hipster burn his mouth? He drank the coffee before it was cool.",
  "Once my dog ate all the Scrabble tiles. He kept leaving little messages around the house.",
  "What's Forest Gump's password? 1Forest1.",
  "Why's it annoying to eat beside basketball players? They dribble all the time.",
  "What starts with E, ends with E, and has only 1 letter in it? Envelope.",
  "I tried to steal spaghetti from the shop, but the female guard saw me and I couldn't get pasta.",
];
