import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import StyledText from "../Text/StyledText";
import TermsAndConditions from "../Result/TermsAndConditions";
import { get, set } from "local-storage";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import {
  VenueConfigContext,
  VenueConfigContextType,
} from "../../contexts/VenueConfigContext";
import YSButton from "../YSButton";
import YSLogo from "../YSLogo";
import YSInput from "../YSInput";
import YSVerticalSpacer from "../YSVerticalSpacer";

export const player_age_key = "ys-player-age";

const getAge = (): number => {
  const age = get<number>(player_age_key);
  return age;
};
const setAge = (age: number): number => {
  set<number>(player_age_key, age);
  return getAge();
};

const StyledWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 1.5rem;
  border-radius: 20px;
  text-align: center;
`;

const StyledLogoContainer = styled.div`
  width: 84px;
  height: 84px;
  margin: 2.5rem auto;
`;

const StyledErrorText = styled(StyledText)`
  color: darkred;
`;

interface CaptureAgeProps {
  onFinished: (type: string) => void;
}

const CaptureAge = ({ onFinished }: CaptureAgeProps) => {
  const { theme: branding } = useContext(
    BrandingContext
  ) as BrandingContextType;
  const { config: venueConfig } = useContext(
    VenueConfigContext
  ) as VenueConfigContextType;

  const [year, setYear] = useState<number | null>(null);
  const yearBounds = [new Date().getFullYear() - 100, new Date().getFullYear()];

  const [ageErrorMessage, setAgeErrorMessage] = useState<string | null>(null);

  const handleYearChange = (event: any) => {
    setYear(parseInt(event.target.value));
  };

  const onPressedPlay = (event: any) => {
    event.preventDefault();
    if (!year) return;
    let age = new Date().getFullYear() - year;
    if (
      venueConfig.playerAgeMinRequired &&
      age < venueConfig.playerAgeMinRequired
    ) {
      setAgeErrorMessage(
        `Sorry, you must be at least ${venueConfig.playerAgeMinRequired!} to play`
      );
    } else if (age <= 0 || age > 85) {
      setAgeErrorMessage("Please try again!");
    } else {
      setAgeErrorMessage(null);
      setAge(age);
      onFinished("capture-age");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <StyledWrapper>
        <StyledLogoContainer style={{ width: 84, height: 84 }}>
          <YSLogo />
        </StyledLogoContainer>
        {ageErrorMessage ? (
          <>
            <StyledErrorText style={{ color: branding.backgroundTextColor }}>
              {ageErrorMessage}
              <br />
              visit{" "}
              <a
                target="_blank"
                href="https://www.drinkaware.co.uk/"
                rel="noreferrer"
              >
                drinkaware.co.uk
              </a>
            </StyledErrorText>
          </>
        ) : (
          <StyledText style={{ color: branding.backgroundTextColor }}>
            Please enter your year of birth to spin!
          </StyledText>
        )}
        <YSVerticalSpacer />
        <YSInput
          textColor={branding.wheelMainColor}
          placeholderTextColor={branding.wheelMainColor}
          placeholder="YYYY"
          type="number"
          min={yearBounds[0]}
          max={yearBounds[1]}
          step="1"
          value={year === null ? "" : year}
          onChange={handleYearChange}
        />
        <YSVerticalSpacer />
        <YSButton text="Play" expandWidth={true} onPressed={onPressedPlay} />
        <TermsAndConditions />
        <YSVerticalSpacer />
      </StyledWrapper>
    </div>
  );
};

export default CaptureAge;
