import React from "react";
import CaptureAge from "./CaptureAge";

interface Props {
  onPreGameFinished: () => void;
}

const PreGame = ({ onPreGameFinished }: Props) => {
  return <CaptureAge onFinished={onPreGameFinished} />;
};

export default PreGame;
