import React, { useEffect, useMemo, useState } from "react";

import TextualWinBase from "./TextualWinBase";
import { resultsAnim } from "./animations";
import { recommendations } from "./edinburghRecommendations";

const EdinburghRecommendation = () => {
  const [animationCompleted, setAnimationCompleted] = useState(false);
  useEffect(() => {
    if (!animationCompleted) {
      resultsAnim();
      setAnimationCompleted(true);
    }
  });

  const recommendation = useMemo(() => {
    const selectedIndex = Math.floor(Math.random() * recommendations.length);
    return recommendations[selectedIndex];
  }, []);

  return <TextualWinBase headingText={recommendation} />;
};

export default EdinburghRecommendation;
