import anime, { AnimeInstance } from "animejs";

const INTRO_ID = "#ys-game-intro";
const FADE_DURATION = 300;

export const fadeAnimFactory = (): AnimeInstance => {
  const spinAnim = anime({
    targets: INTRO_ID,
    easing: "easeInSine",
    opacity: 0,
    duration: FADE_DURATION,
    autoplay: false,
  });
  return spinAnim;
};

export const fadeInTitleAnimFactory = (): AnimeInstance => {
  const spinAnim = anime({
    targets: INTRO_ID,
    easing: "easeInSine",
    opacity: 1,
    duration: FADE_DURATION,
    autoplay: false,
  });
  return spinAnim;
};
