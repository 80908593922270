import React from "react";
import { animated, useSpring } from "@react-spring/web";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import StyledText from "../Text/StyledText";
import YSInput from "../YSInput";
import YSVerticalSpacer from "../YSVerticalSpacer";
import YSButton from "../YSButton";

type QuizRoundQuestionProps = {
  question: string;
  onAnswered: (answer: string) => void;
};

const QuizRoundQuestion = ({
  question,
  onAnswered,
}: QuizRoundQuestionProps) => {
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;

  const springs = useSpring({
    from: { scale: 0.3, opacity: 0 },
    to: { scale: 1.0, opacity: 1 },
  });

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      answer: { value: string };
    };
    const answer = target.answer.value;
    onAnswered && onAnswered(answer);
  };

  return (
    <form onSubmit={handleSubmit}>
      <animated.div style={{ overflow: "hidden", ...springs }}>
        <StyledText style={{ color: branding.backgroundTextColor }}>
          {question}
        </StyledText>
        <YSVerticalSpacer />
        <YSInput
          textColor={branding.wheelMainColor}
          placeholderTextColor={branding.wheelMainColor}
          name="answer"
          type="text"
          autoFocus={true}
          placeholder="..."
        />
        <YSVerticalSpacer />
      </animated.div>
      <YSButton type="submit" text="Submit" expandWidth={true} />
    </form>
  );
};

export default QuizRoundQuestion;
