import React, { useEffect, useMemo, useState } from "react";

import TextualWinBase from "./TextualWinBase";
import { resultsAnim } from "./animations";
import { jokes } from "./jokes";

const Joke = () => {
  const [animationCompleted, setAnimationCompleted] = useState(false);
  useEffect(() => {
    if (!animationCompleted) {
      resultsAnim();
      setAnimationCompleted(true);
    }
  });

  const joke = useMemo(() => {
    const selectedIndex = Math.floor(Math.random() * jokes.length);
    return jokes[selectedIndex];
  }, []);

  return <TextualWinBase headingText={joke} />;
};

export default Joke;
