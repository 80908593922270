import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchTicket,
  fetchVenue,
  submitSurveyAnswer,
  Ticket,
  VenueDto,
} from "../../API";
import Launch from "../Launch/Launch";
import PreGame from "../PreGame/PreGame";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import {
  VenueConfigContext,
  VenueConfigContextType,
} from "../../contexts/VenueConfigContext";
import { useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";
import SpinTheWheel from "../../games/SpinTheWheel/SpinTheWheel";
import Modal from "../Utility/Modal";
import styled from "@emotion/styled";

const QuestionnaireOption = styled.div<{
  color?: string;
  background?: string;
  fontSize?: string;
}>`
  padding: 12px 32px 12px 32px;
  font-size: ${(props) => props.fontSize || "16px"};
  font-weight: 900;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: ${(props) => props.color || "#FFF"};
  background: ${(props) => props.background || "#ECAC8D"};
  background-color: ${(props) => props.background || "#ECAC8D"};
  max-width: 50%;
  justify-self: center;
  align-self: center;
  margin-top: 1rem;
  width: 10rem;
  z-index: 3000;
`;

export type GameRouteParams = {
  venueShortName: string;
  ticketId: string;
};

const Game = () => {
  const { venueShortName, ticketId } = useParams() as GameRouteParams;
  const [preGameCompleted, setPreGameCompleted] = useState<boolean>(false);

  const { data } = useQuery<VenueDto, AxiosError>(
    ["venueData", venueShortName],
    () => fetchVenue(venueShortName),
    {
      retry: 1,
    }
  );

  const { data: ticket } = useQuery<Ticket, AxiosError>(
    ["ticket", ticketId],
    () => fetchTicket(venueShortName, ticketId),
    {
      retry: 1,
      enabled: !!venueShortName,
    }
  );

  const { updateTheme } = React.useContext(
    BrandingContext
  ) as BrandingContextType;
  const { updateConfig } = React.useContext(
    VenueConfigContext
  ) as VenueConfigContextType;
  useEffect(() => {
    if (data) {
      updateTheme(data);
      updateConfig(data);
    }
  }, [data]);

  const [allowInteraction, setAllowInteraction] = useState<boolean>(false);
  const [isGameFinished, setIsGameFinished] = useState<boolean>(false);

  useEffect(() => {
    if (
      allowInteraction &&
      isGameFinished &&
      !isQuestionnaireOpen &&
      !isQuestionnaireCompleted
    ) {
      setTimeout(() => {
        setIsQuestionnaireOpen(true);
      }, 2000);
    }
  }, [allowInteraction, isGameFinished, ticket]);

  const [isQuestionnaireOpen, setIsQuestionnaireOpen] = useState(false);
  const [isQuestionnaireCompleted, setIsQuestionnaireCompleted] =
    useState(false);
  const surveyAnswerMutation = useMutation({
    mutationFn: submitSurveyAnswer,
  });
  const onSelectQuestionnaireAnswer = (answer: string) => {
    if (isQuestionnaireCompleted) return;
    surveyAnswerMutation.mutate({
      venueShortName: venueShortName,
      ticketId: ticketId,
      answer: answer,
    });

    setIsQuestionnaireOpen(false);
    setIsQuestionnaireCompleted(true);
  };

  if (!data || !ticket) return null;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflowY: allowInteraction ? "visible" : "hidden",
        pointerEvents: allowInteraction ? "auto" : "none",
        fontFamily: `"Poppins", sans-serif`,
      }}
    >
      {!allowInteraction && (
        <Launch
          onLaunchAnimationsFinished={() => {
            setAllowInteraction(true);
          }}
        />
      )}

      {data.campaign.playerAgeCollectionEnabled &&
      !preGameCompleted &&
      ticket.shouldCollectPlayerAge ? (
        <PreGame
          onPreGameFinished={() => {
            setPreGameCompleted(true);
          }}
        />
      ) : (
        <SpinTheWheel
          ticket={ticket}
          prizes={data.prizes}
          isPlayable={allowInteraction}
          onGameFinished={() => {
            setIsGameFinished(true);
          }}
        />
      )}

      {data.campaign.surveyQuestion && data.campaign.surveyPossibleAnswers && (
        <Modal title={data.campaign.surveyQuestion} shown={isQuestionnaireOpen}>
          {data.campaign.surveyPossibleAnswers.map((answer) => (
            <QuestionnaireOption
              key={answer}
              onClick={() => onSelectQuestionnaireAnswer(answer)}
              color={data.campaign.wheelMainTextColor}
              background={data.campaign.wheelMainColor}
            >
              {answer}
            </QuestionnaireOption>
          ))}
        </Modal>
      )}
    </div>
  );
};

export default Game;
