import React, { useEffect, useMemo, useState } from "react";

import { resultsAnim } from "./animations";
import { questions } from "./triviaQuestions";
import LabelText from "./LabelText";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import StyledText from "../Text/StyledText";
import YSButton from "../YSButton";

const TriviaQuestion = () => {
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;
  const [showAnswer, setShowAnswer] = useState<boolean>(false);

  const [animationCompleted, setAnimationCompleted] = useState(false);
  useEffect(() => {
    if (!animationCompleted && !showAnswer) {
      resultsAnim();
      setAnimationCompleted(true);
    }
  });

  const [question, answer] = useMemo(() => {
    const selectedIndex = Math.floor(Math.random() * questions.length);
    return questions[selectedIndex];
  }, []);

  return (
    <>
      <StyledText
        style={{ color: branding.backgroundTextColor, marginBottom: "2rem" }}
      >
        {question}
      </StyledText>
      <br />
      {showAnswer ? (
        <LabelText
          text={answer}
          color={branding.backgroundTextColor}
          borderColor={branding.wheelMainColor}
          expand={false}
        />
      ) : (
        <YSButton
          text="Reveal answer"
          expandWidth={true}
          onPressed={() => setShowAnswer(true)}
        />
      )}
    </>
  );
};

export default TriviaQuestion;
