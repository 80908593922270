import anime, {
  AnimeAnimParams,
  AnimeInstance,
  AnimeTimelineInstance,
} from "animejs";

const COLOUR_TRANSITION = 100;
const SEGMENT_CLASS = ".ys-wheel-segment";
const PINK_SEGMENT_CLASS = `${SEGMENT_CLASS}-pink`;
const BLUE_SEGMENT_CLASS = `${SEGMENT_CLASS}-blue`;
const SPIN_DURATION = 10400;
const WHEEL_ID = "#ys-wheel";
const TOGGLE_DURATION = 150;
const POINTER_ID = "#ys-wheel-pointer";
const CONTAINER_ID = "#ys-spin-wheel-container";

export const flashAnimFactory = (): AnimeTimelineInstance => {
  const flashSegments = ({
    targets,
    delay,
  }: AnimeAnimParams): AnimeAnimParams => ({
    targets,
    opacity: [
      { value: 1, duration: 0 },
      { value: 0.8, duration: COLOUR_TRANSITION },
      { value: 1, duration: COLOUR_TRANSITION },
    ],
    delay,
  });

  const flashAnim = anime.timeline({
    easing: "easeInOutQuad",
    autoplay: false,
    loop: true,
  });
  flashAnim.add(
    flashSegments({
      targets: SEGMENT_CLASS,
      delay: anime.stagger(COLOUR_TRANSITION * 2),
    }),
    "+=300"
  );
  flashAnim.add(
    flashSegments({
      targets: PINK_SEGMENT_CLASS,
    }),
    "+=300"
  );
  flashAnim.add(
    flashSegments({
      targets: BLUE_SEGMENT_CLASS,
    })
  );
  flashAnim.add(
    flashSegments({
      targets: PINK_SEGMENT_CLASS,
    })
  );
  return flashAnim;
};

export const spinAnimFactory = (): AnimeInstance => {
  // randomly finish on blue or pink segment
  const randomTruthy = anime.random(0, 1) % 2;
  const spin = -360 * 9;
  const rotate = randomTruthy ? spin : spin - 30;

  const spinAnim = anime({
    targets: WHEEL_ID,
    easing: "easeOutSine",
    duration: SPIN_DURATION,
    rotate,
    autoplay: false,
  });

  return spinAnim;
};

export const toggleAnimFactory = (): AnimeInstance => {
  const toggleAnim = anime({
    targets: POINTER_ID,
    easing: "easeInOutBack",
    rotate: [{ value: 5 }, { value: -5 }, { value: 0 }],
    duration: TOGGLE_DURATION,
    autoplay: false,
    loop: Math.round(SPIN_DURATION / TOGGLE_DURATION) - 22,
    loopComplete: () => {
      anime({
        targets: POINTER_ID,
        easing: "easeInOutBack",
        rotate: [{ value: 5 }, { value: -5 }, { value: 0 }],
        duration: TOGGLE_DURATION * 1.6,
        autoplay: true,
        loop: 11,
      });
    },
  });
  return toggleAnim;
};

export const slideWheelAnimFactory = (): AnimeInstance => {
  const slideWheelAnim = anime({
    targets: CONTAINER_ID,
    height: 270,
    easing: "easeOutSine",
    autoplay: false,
  });
  return slideWheelAnim;
};

export const hideWheelAnimFactory = (): AnimeInstance => {
  const slideWheelAnim = anime({
    targets: CONTAINER_ID,
    height: [270, 0],
    easing: "easeInSine",
    autoplay: false,
  });
  return slideWheelAnim;
};
