import React from "react";
import styled from "@emotion/styled";

import {
  BrandingContext,
  BrandingContextType,
} from "../contexts/BrandingContext";

const StyledTitleText = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 32px;
  margin: 0 0;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  opacity: 1;
`;

interface YSTitleTextProps {
  text: string;
}

const YSButton = ({ text }: YSTitleTextProps) => {
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;

  return (
    <StyledTitleText color={branding.backgroundTextColor}>
      {text}
    </StyledTitleText>
  );
};

export default YSButton;
