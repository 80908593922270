import anime from "animejs";

export const carouselFastAnimFactory = () => {
  const elements = Array.from(document.querySelectorAll(".ys-prize"));
  const carouselAnim = anime({
    targets: elements.slice(0, 6),
    translateX: [400, -400],
    duration: 800,
    easing: "easeInOutQuad",
    autoplay: false,
    delay: anime.stagger(800),
  });
  return carouselAnim;
};

export const carouselSlowAnimFactory = () => {
  const elements = Array.from(document.querySelectorAll(".ys-prize"));
  const carouselAnim = anime({
    targets: elements.slice(6, 9),
    translateX: [400, -400],
    duration: 1400,
    easing: "easeInOutQuad",
    autoplay: false,
    delay: anime.stagger(1400),
  });
  return carouselAnim;
};

export const prizeAnimFactory = () => {
  const prizeAnim = anime({
    targets: ".ys-ticket-prize",
    translateX: [400, 0],
    duration: 800,
    easing: "easeOutQuad",
    autoplay: false,
  });
  return prizeAnim;
};
