import React from "react";

import Prize from "./Prize";

interface Props {
  image: string;
  isPrizeWon: boolean;
  showPrizeEffects: boolean;
}

const TicketPrize = ({ image, isPrizeWon, showPrizeEffects }: Props) => {
  if (showPrizeEffects) {
    if (isPrizeWon) {
      return (
        <Prize
          className="ys-ticket-prize"
          image={image}
          confetti
          celebrate={true}
        />
      );
    } else {
      return (
        <Prize className="ys-ticket-prize" image={image} commiserate={true} />
      );
    }
  }

  return <Prize className="ys-ticket-prize" image={image} />;
};

export default TicketPrize;
