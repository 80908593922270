import React, { useState } from "react";
import styled from "@emotion/styled";

import { fadeAnimFactory, fadeInTitleAnimFactory } from "./animations";
import anime from "animejs";
import YSButton from "../YSButton";
import YSTitleText from "../YSTitleText";
import YSVerticalSpacer from "../YSVerticalSpacer";

const NO_WIN_PRIZES: string[] = ["no-prize", "no-win", "a-joke"];

const Wrapper = styled.div`
  margin-top: 2.5rem;
  display: flex;
  width: 100%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
`;

interface TitleProps {
  ticket: Ticket;
  showResult: boolean;
  isWheelSpinning: boolean;
}

const Title = ({ ticket, showResult, isWheelSpinning }: TitleProps) => {
  const [fadeAnimation, setFadeAnimation] = useState<
    ReturnType<typeof anime> | undefined
  >();
  const [fadeInAnimation, setFadeInAnimation] = useState<
    ReturnType<typeof anime> | undefined
  >();

  React.useEffect(() => {
    setFadeAnimation(fadeAnimFactory());
    setFadeInAnimation(fadeInTitleAnimFactory());
  }, []);

  if (isWheelSpinning && fadeAnimation && !fadeAnimation.began) {
    fadeAnimation?.play();
  }

  if (showResult && fadeInAnimation && !fadeInAnimation.began) {
    fadeInAnimation.play();
  }
  const getTitleText = () => {
    if (showResult && ticket) {
      if (NO_WIN_PRIZES.includes(ticket.outcome)) {
        return "No prize";
      } else if (ticket.outcome === "win-conversation-starter") {
        return "Conversation starter";
      } else if (ticket.outcome === "win-trivia-question") {
        return "Trivia question";
      } else if (ticket.outcome === "quiz-round") {
        return "Mystery prize";
      } else if (ticket.outcome === "quiz-round-expired") {
        return "Time ran out";
      } else if (ticket.outcome === "quiz-round-no-win") {
        return "Wrong answer";
      } else {
        return "You're a winner!";
      }
    }
    return "Are you ready?";
  };

  return (
    <Wrapper>
      <div
        id="ys-game-intro"
        style={{ width: "100%", paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
      >
        <YSTitleText text={getTitleText()} />
        {!showResult && (
          <>
            <YSVerticalSpacer />
            <YSButton text="Spin now" expandWidth={true} onPressed={() => {}} />
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default Title;
