import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./css/reset.css";
import Home from "./components/Home/Home";
import Game from "./components/Game/Game";
import Start from "./components/Start/Start";
import { VenueConfigProvider } from "./contexts/VenueConfigContext";
import BrandingProvider from "./contexts/BrandingContext";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/start/:venueShortName/:cardId",
    element: <Start />,
  },
  {
    path: "/:venueShortName/:ticketId",
    element: <Game />,
  },
  {
    path: "*",
    element: <Home />,
  },
]);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrandingProvider>
        <VenueConfigProvider>
          <RouterProvider router={router} />
        </VenueConfigProvider>
      </BrandingProvider>
    </QueryClientProvider>
  );
};

export default App;
