import styled from "@emotion/styled";
import React, { useContext } from "react";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import { claimPrize } from "../../API";
import { GameRouteParams } from "../Game/Game";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";

const StyledContainer = styled.div`
  text-align: center;
  font-family: "Barlow Semi Condensed", sans-serif;
  padding: 1.25rem;
  border-radius: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

interface Props {
  linkUrl: string;
}

const RedemptionLink = ({ linkUrl }: Props) => {
  const { venueShortName, ticketId } = useParams() as GameRouteParams;

  const { theme: branding } = useContext(
    BrandingContext
  ) as BrandingContextType;

  const claimPrizeMutation = useMutation({
    mutationFn: claimPrize,
    onSuccess: () => {},
  });

  const onPressedClaimPrize = () => {
    claimPrizeMutation.mutate({
      venueShortName,
      ticketId,
    });
  };

  return (
    <a
      onClick={() => onPressedClaimPrize()}
      href={linkUrl}
      target="_blank"
      rel="noreferrer"
      style={{
        color: branding.backdropTextColor,
        marginTop: "1.25rem",
        marginBottom: "1.25rem",
      }}
    >
      <StyledContainer
        style={{
          backgroundColor: branding.backdropColor,
        }}
      >
        CLICK HERE
      </StyledContainer>
    </a>
  );
};

export default RedemptionLink;
