import React from "react";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";

interface Props {
  className?: string;
}

const Pointer = ({ className }: Props) => {
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;

  return (
    <svg
      id="ys-wheel-pointer"
      className={className}
      width="64"
      height="69"
      viewBox="0 0 64 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_49_155)">
        <path
          d="M48.9037 9H15.0963C12.2168 9 10.2806 11.9509 11.4269 14.5924L28.3306 53.5445C29.7237 56.7546 34.2763 56.7546 35.6694 53.5445L52.5731 14.5924C53.7194 11.9509 51.7832 9 48.9037 9Z"
          fill={branding.wheelSecondaryColor}
        />
        <path
          d="M48.9037 7H15.0963C10.777 7 7.87274 11.4263 9.59219 15.3886L26.4959 54.3407C28.5856 59.1559 35.4144 59.1559 37.5041 54.3406L54.4078 15.3886C56.1273 11.4263 53.223 7 48.9037 7Z"
          stroke="white"
          strokeWidth="4"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_49_155"
          x="0.0875244"
          y="0"
          width="63.825"
          height="68.9521"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_49_155"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_49_155"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Pointer;
