import React from "react";
import styled from "@emotion/styled";

import {
  BrandingContext,
  BrandingContextType,
} from "../contexts/BrandingContext";

interface StyledButtonProps {
  color: string;
  backgroundColor: string;
  expandWidth: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 8px;
  padding: 0.5rem 0;
  margin: 1rem 0;
  width: ${(props) => (props.expandWidth ? "100%" : "auto")};
  border: 1.5px solid ${(props) => props.color};
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
`;

interface YSButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  onPressed?: (event: any) => void;
  expandWidth?: boolean;
}

const YSButton = ({
  text,
  onPressed,
  expandWidth,
  ...props
}: YSButtonProps) => {
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;

  return (
    <StyledButton
      color={branding.wheelMainTextColor}
      backgroundColor={branding.wheelMainColor}
      onClick={onPressed}
      expandWidth={expandWidth || false}
      {...props}
    >
      {text}
    </StyledButton>
  );
};

export default YSButton;
