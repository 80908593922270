export const questions: Array<[string, string]> = [
  ["How many languages are written from right to left?", "Twelve"],
  ["What is the rarest M&M colour?", "Brown"],
  ["Which country consumes the most chocolate per capita?", "Switzerland"],
  ["What was the first soft drink in space?", "Coca-cola"],
  ["What is the most consumed manufactured drink in the world?", "Tea"],
  ["What is the only edible food that never goes bad?", "Honey"],
  ["Which country invented ice cream?", "China"],
  [
    "What was the first toy to be advertised in a movie?",
    "Mr potato head from toy story",
  ],
  [
    "What country touches the Indian Ocean, the Arabian Sea, and the Bay of Bengal?",
    "India",
  ],
  [
    "What's the city with the most diversity in terms of language?",
    "New York City",
  ],
  ["What is the loudest animal on Earth?", "Sperm whale"],
  ["How many hearts does an octopus have?", "Three"],
  ["What two mammals lay eggs?", "Spiny anteater and the duck-billed platypus"],
  ['The name of which African animal means "river horse"', "Hippopotamus"],
  [
    "In the state of Georgia, it's illegal to eat what with a fork?",
    "Fried chicken",
  ],
  ["What is the most common human eye colour?", "Brown"],
  ["What is the largest type of deer?", "The moose"],
  ["What does BMW stand for?", "Bavarian Motor Works"],
  ["Which bone are babies born without?", "Kneecap"],
  ["How many eyes does a bee have?", "Five"],
  ["Who was the first woman to win a noble prize?", "Marie Curie"],
  [
    "In which film did father and son actors Will and Jaden Smith first appear together?",
    "The Pursuit of Happiness",
  ],
  ["Where did Heineken beer originate?", "Amsterdam, Netherlands"],
  ["How many signs are there in the Zodiac?", "Twelve"],
  [
    "What are the two fruit juices in a cosmopolitan cocktail?",
    "Cranberry and Lime",
  ],
  ["What is the only fruit that has seeds on the outside?", "Strawberry"],
  [
    "Robin Williams won the best supporting actor Oscar for what film?",
    "Good Will Hunting",
  ],
  ["What colour is a polar bear's skin?", "Black"],
  ['The movie "Happy Feet" features what animal?', "Penguins"],
  ["What is the world's largest land mammal?", "Elephant"],
  [
    "Which Middle Eastern city is also the name of a type of artichoke?",
    "Jerusalem",
  ],
  [
    'What song are the following lyrics from? "I got this feeling inside my bones, It goes electric, wavey when I turn it on."',
    "Justin Timberlake - Can't Stop the Feeling",
  ],
];
