export const facts: string[] = [
  'In 1959 an "electric blanket" was installed under the road on The Mound to keep it clear of ice and snow.',
  "Edinburgh Castle was once home to an elephant from Sri Lanka. When he was there, he developed a taste for beer.",
  "The Balmoral Hotel's clock runs three minutes fast, to help people arrive on time for their trains at Waverley station",
  "Princes St Gardens is home to the Wojtek the Soldier Bear Memorial. Wojtek was a bear cub, adopted by Polish soldiers.",
  "The Edinburgh Fringe Festival is the largest arts festival in the world.",
  "Edinburgh wasn't always the capital of Scotland.",
  "Edinburgh Castle sits on an extinct volcano.",
  "A unicorn is the national animal of Scotland.",
  "Edinburgh has 112 parks and more trees per head of population than any other city in the UK.",
  'The National Monument on Calton Hill is known as "Edinburgh\'s Shame".',
  "Edinburgh has more listed buildings than anywhere in the world.",
  "The Royal Mile isn't technically a mile long, it's 1.13 miles long.",
  "Edinburgh Zoo is home to the only knighted penguin in the world, he is the mascot of the Norwegian King's Guard.",
  "There's an old railway tunnel that runs underground beside Arthurs Seat, today you can walk or cycle through.",
  "Edinburgh's Princes Street Gardens was once a lake turned sewer and dumping ground for dead bodies, Nor Loch.",
];
