export const recommendations: string[] = [
  "Get a takeaway coffee or cocktail and take a walk up to the Castle.",
  "Visit the roof terrace at the National Museum of Scotland for great views of the city.",
  "Visit Duddingston Loch and see if you can spot an otter.",
  "Visit Dolly the Sheep at the National Museum of Scotland.",
  "Get your lunch to take away and enjoy in the gardens at Dunbars Close or Arthurs Seat.",
  "Visit the Botanical Gardens, for peace and serenity amongst 72 acres of stunning scenery.",
  "Take a short 5-minute walk to Market Street to visit Edinburgh Dungeons.",
  "Get a takeaway drink or food item and walk along to the Meadows to soak up the atmosphere.",
  "If you are here in August, visit an Edinburgh Festival show!",
  "Check out St James Quarter built in 2021,",
  "On a rainy day, visit Camera Obscura. Over 100 illusions and rooftop views of Edinburgh.",
];
