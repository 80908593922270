import React, { useEffect } from "react";
import styled from "@emotion/styled";
import anime from "animejs";
import { Howl } from "howler";
import win01 from "../../audio/win-alright.wav";
import win02 from "../../audio/win-fantastic.wav";
import win03 from "../../audio/win-woohoo.wav";
import win04 from "../../audio/win-yipee.wav";
import sad from "../../audio/sad-trombone.wav";
import Image from "./Image";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";

const wins = [win01, win02, win03, win04];
const winAudio = new Howl({
  src: [wins[anime.random(0, 3)]],
});
const loseAudio = new Howl({
  src: [sad],
});

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 246px;
  position: absolute;
  top: 0px;
  transform: translateX(600px);
`;

const Svg = styled.svg`
  scale: 1.35;
`;

interface Props {
  className?: string;
  confetti?: boolean;
  celebrate?: boolean;
  commiserate?: boolean;
  image?: string;
}

const Prize = ({
  className,
  confetti,
  celebrate,
  commiserate,
  image,
}: Props) => {
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;

  if (celebrate && commiserate) {
    commiserate = false;
  }

  useEffect(() => {
    if (confetti) {
      anime({
        targets: ".ys-confetti",
        scale: 0.97,
        direction: "alternate",
        loop: true,
        easing: "easeInOutBack",
        duration: 700,
        delay: anime.stagger(40, { grid: [2, 6], from: "first" }),
      });
    }

    if (celebrate) {
      !winAudio.playing() && winAudio.play();
    }
    if (commiserate) {
      !loseAudio.playing() && loseAudio.play();
    }
  }, [confetti, celebrate, commiserate]);

  const cls = className ? ` ${className}` : "";

  return (
    <Wrapper className={`ys-prize${cls}`}>
      <Svg viewBox="0 0 467 328" xmlns="http://www.w3.org/2000/svg">
        <circle cx="233.5" cy="164" r="120" fill={branding.backdropColor} />
      </Svg>
      <Image src={image} />
    </Wrapper>
  );
};

export default Prize;
