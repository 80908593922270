import React, { useEffect, useMemo, useState } from "react";

import { resultsAnim } from "./animations";
import { starters } from "./conversationStarters";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import StyledText from "../Text/StyledText";

const ConversationStarter = () => {
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;

  const [animationCompleted, setAnimationCompleted] = useState(false);
  useEffect(() => {
    if (!animationCompleted) {
      resultsAnim();
      setAnimationCompleted(true);
    }
  });

  const starter = useMemo(() => {
    const selectedIndex = Math.floor(Math.random() * starters.length);
    return starters[selectedIndex];
  }, []);

  return (
    <StyledText
      style={{ color: branding.backgroundTextColor, marginBottom: "2rem" }}
    >
      {starter}
    </StyledText>
  );
};

export default ConversationStarter;
