import { createContext, useState } from "react";
import { VenueDto } from "../API";

export type Branding = {
  campaignSplashImage?: string;
  venueSplashImage?: string;
  wheelMainColor: string;
  wheelMainTextColor: string;
  wheelSecondaryColor: string;
  wheelSecondaryTextColor: string;
  backgroundColor: string;
  backgroundTextColor: string;
  backdropColor: string;
  backdropTextColor: string;
  noPrizeImage?: string;
};

export const defaultBranding = {
  wheelMainColor: "#f5e76e",
  wheelMainTextColor: "#000000",
  wheelSecondaryColor: "#032e3e",
  wheelSecondaryTextColor: "#ffffff",
  backgroundColor: "#85c7f2",
  backgroundTextColor: "#ffffff",
  backdropColor: "#57b2ed",
  backdropTextColor: "#ffffff",
  noPrizeImage:
    "https://yourspin-live-image-assets.s3.eu-west-2.amazonaws.com/the-pear-tree/prizes/no-prize.png",
};

export type BrandingContextType = {
  theme: Branding;
  updateTheme: (data: VenueDto) => void;
};

export const BrandingContext = createContext<BrandingContextType | undefined>(
  undefined
);

export const BrandingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useState<Branding>(defaultBranding);

  const updateTheme = (data: VenueDto) => {
    setTheme({
      venueSplashImage: data.venue.splashImage || undefined,
      campaignSplashImage: data.campaign.splashImage || undefined,
      wheelMainColor:
        data.campaign.wheelMainColor || defaultBranding.wheelMainColor,
      wheelMainTextColor:
        data.campaign.wheelMainTextColor || defaultBranding.wheelMainTextColor,
      wheelSecondaryColor:
        data.campaign.wheelSecondaryColor ||
        defaultBranding.wheelSecondaryColor,
      wheelSecondaryTextColor:
        data.campaign.wheelSecondaryTextColor ||
        defaultBranding.wheelSecondaryTextColor,
      backgroundColor:
        data.campaign.backgroundColor || defaultBranding.backgroundColor,
      backgroundTextColor:
        data.campaign.backgroundTextColor ||
        defaultBranding.backgroundTextColor,
      backdropColor:
        data.campaign.backdropColor || defaultBranding.backdropColor,
      backdropTextColor:
        data.campaign.backdropTextColor || defaultBranding.backdropTextColor,
      noPrizeImage: data.campaign.noPrizeImage || defaultBranding.noPrizeImage,
    });

    const backgroundColor =
      data.campaign.backgroundColor || defaultBranding.backgroundColor;
    document.body.style.backgroundColor = backgroundColor;
    document
      .querySelector("meta[name='theme-color']")
      ?.setAttribute("content", backgroundColor);
  };

  return (
    <BrandingContext.Provider value={{ theme, updateTheme }}>
      {children}
    </BrandingContext.Provider>
  );
};

export default BrandingProvider;
