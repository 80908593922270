import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import StyledText from "../Text/StyledText";

function hexToRGB(hex?: string, alpha?: number) {
  if (typeof hex === "undefined") hex = "#FFFFFF";
  if (typeof alpha === "undefined") alpha = 0.85;
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

const ModalContainer = styled.div<{
  height?: string;
  width?: string;
  backdrop?: string;
  opacity?: number;
}>`
  background-color: ${(props) =>
    hexToRGB(props.backdrop, props.opacity) || hexToRGB("#FFFFFF")};

  position: absolute;
  z-index: 2000;

  left: 0;
  right: 0;

  top: 30%;
  margin-left: 1rem;
  margin-right: 1rem;
  height: ${(props) => props.height || "400px"};
  padding-bottom: 2.5rem;
  box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.1);
  /* Ensure children are fully opaque */
`;

const ModalHeading = styled(StyledText)<{ color?: string }>`
  margin-bottom: 1rem;
  text-align: center;
  margin-top: 3rem;
  font-size: 24px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: ${(props) => props.color || "white"};
`;

const ModalText = styled.p<{ color?: string }>`
  font-size: 16px;
  color: ${(props) => props.color || "white"};
  font-family: "Fira Mono";
`;

const ModalChildren = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CloseButton = styled.button<{ color?: string }>`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 23px;
  color: ${(props) => props.color || "red"};
`;

interface Props {
  height?: string;
  width?: string;
  opacity?: number;
  title?: string;
  text?: string;
  children?: any;
  shown?: boolean;
}

const Modal: React.FC<Props> = ({
  title,
  text,
  height,
  width,
  opacity,
  children,
  shown,
}: Props) => {
  const [isOpen, setIsOpen] = useState(shown ? true : false);
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;

  useEffect(() => {
    setIsOpen(shown ? true : false); // Update show state dynamically
  }, [shown]);

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!height) height = "auto";
  if (!width) width = "100%";

  return isOpen ? (
    <ModalContainer
      opacity={opacity}
      height={height}
      width={width}
      backdrop={branding.wheelSecondaryColor}
    >
      <ModalHeading color={branding.wheelSecondaryTextColor}>
        {title}
      </ModalHeading>
      <ModalText color={branding.wheelSecondaryTextColor}>{text}</ModalText>

      <ModalChildren>{children}</ModalChildren>
    </ModalContainer>
  ) : null;
};

export default Modal;
