import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { resultsAnim } from "./animations";
import LabelText from "./LabelText";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import RedemptionLink from "./RedemptionLink";
import { useTimer } from "react-timer-hook";
import {
  VenueConfigContext,
  VenueConfigContextType,
} from "../../contexts/VenueConfigContext";
import { claimPrize } from "../../API";
import { useMutation, useQueryClient } from "react-query";
import { GameRouteParams } from "../Game/Game";
import { useParams } from "react-router-dom";
import TermsAndConditionsShort from "./TermsAndConditionsShort";
import YSButton from "../YSButton";

interface Props {
  ticket: Ticket;
  onConfettiStart?: () => void;
  onConfettiStop?: () => void;
}

const RedeemInstructionsContainer = styled.div`
  margin-top: 2rem;
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: 400;
  color: white;
`;

interface StyledTimerProps {
  isExpired: boolean;
  textColor: string;
}

const StyledTimer = styled.div<StyledTimerProps>`
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: ${(props) => (props.isExpired ? "line-through" : "none")};
  color: ${(props) => props.textColor};
  width: 100%;
  text-align: center;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
`;

const Win = ({ ticket, onConfettiStart, onConfettiStop }: Props) => {
  const { venueShortName } = useParams() as GameRouteParams;

  const [animationCompleted, setAnimationCompleted] = useState(false);
  useEffect(() => {
    if (!animationCompleted) {
      resultsAnim();
      setAnimationCompleted(true);
    }
  });

  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;
  const { config: venueConfig } = React.useContext(
    VenueConfigContext
  ) as VenueConfigContextType;

  const {
    hours,
    minutes,
    seconds,
    isRunning: isTimerRunning,
    restart: restartTimer,
    totalSeconds,
  } = useTimer({
    autoStart: false,
    expiryTimestamp: new Date(),
    onExpire: () => {
      onConfettiStop && onConfettiStop();
    },
  });

  useEffect(() => {
    if (ticket.redeemedAt && !ticket.redemptionLink && !isTimerRunning) {
      let expiryDate = new Date(ticket.redeemedAt);
      expiryDate.setMinutes(expiryDate.getMinutes() + 10);
      if (expiryDate > new Date()) {
        restartTimer(expiryDate);
        onConfettiStart && onConfettiStart();
      }
    }
  }, [ticket.redeemedAt, ticket.redemptionLink, isTimerRunning]);

  const queryClient = useQueryClient();
  const claimPrizeMutation = useMutation({
    mutationFn: claimPrize,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["ticket", ticket.id] });
    },
  });

  const onPressedClaimPrize = () => {
    if (isTimerRunning) {
      return;
    }
    claimPrizeMutation.mutate({
      venueShortName,
      ticketId: ticket.id,
    });
  };

  function getTimerDisplayText(): string {
    return `${hours.toLocaleString("en-GB", {
      minimumIntegerDigits: 2,
    })}:${minutes.toLocaleString("en-GB", {
      minimumIntegerDigits: 2,
    })}:${seconds.toLocaleString("en-GB", { minimumIntegerDigits: 2 })}`;
  }

  return (
    <>
      {ticket.displayText?.length > 0 && (
        <div style={{ marginTop: "1.5rem" }}>
          <LabelText
            text={ticket.displayText}
            color={branding.backgroundTextColor}
            borderColor={branding.wheelMainColor}
            expand={false}
          />
        </div>
      )}
      <div style={{ flexGrow: 1 }} />
      <RedeemInstructionsContainer
        style={{ color: branding.backgroundTextColor }}
      >
        {ticket.redemptionLink?.length > 0 ? (
          <RedemptionLink linkUrl={ticket.redemptionLink} />
        ) : (
          <>
            {ticket.redeemedAt !== null &&
            (isTimerRunning || totalSeconds == 0) ? (
              <>
                <StyledTimer
                  isExpired={totalSeconds == 0}
                  textColor={branding.backgroundTextColor}
                >
                  {getTimerDisplayText()}
                </StyledTimer>
                {venueConfig.venueName}
                <br />
                {new Date(ticket.redeemedAt || ticket.playedAt).toLocaleString(
                  "en-GB",
                  { dateStyle: "short", timeStyle: "short" }
                )}
              </>
            ) : (
              <>
                <div style={{ fontSize: "1.5rem" }}>
                  {ticket.redemptionNotes}
                </div>
                <YSButton
                  text="Claim prize"
                  expandWidth={true}
                  onPressed={onPressedClaimPrize}
                />
                <div style={{ fontSize: "1rem" }}>
                  You'll have 10 minutes to show this to your server
                </div>
                <TermsAndConditionsShort />
              </>
            )}
          </>
        )}
      </RedeemInstructionsContainer>
      <div style={{ flexGrow: 1 }} />
    </>
  );
};

export default Win;
