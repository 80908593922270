import React from "react";
import styled from "@emotion/styled";

interface SplashProps {
  image: string;
}

const Splash = styled.div<SplashProps>`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  opacity: 0;
  box-sizing: border-box;
  padding: 0.8rem 1rem;
  width: 100%;
  background-image: url(${({ image }) => CSS.escape(image)});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-grow: 1;
`;

interface Props extends SplashProps {}

const VenueSplash = ({ image }: Props) => (
  <Splash className="ys-splash" image={image} />
);

export default VenueSplash;
