import styled from "@emotion/styled";

const StyledText = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  text-transform: uppercase;
`;

export default StyledText;
