import React, { useEffect, useRef } from "react";
import anime from "animejs";
import styled from "@emotion/styled";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import YSLogo from "../YSLogo";

interface StyledWrapperProps {
  backgroundColor: string;
}
const StyledWrapper = styled.button<StyledWrapperProps>`
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  width: 84px;
  height: 84px;
  border: 4px solid white;
`;

interface ButtonProps {
  className?: string;
}
const Button = ({ className }: ButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { theme: branding } = React.useContext(
    BrandingContext
  ) as BrandingContextType;

  useEffect(() => {
    const button = buttonRef.current;
    if (button) {
      const pressAnim = anime.timeline({
        autoplay: false,
      });
      pressAnim.add(
        {
          targets: "#ys-wheel-button-lowlight",
          fillOpacity: 0.1,
        },
        0
      );
      pressAnim.add(
        {
          targets: "#ys-wheel-button-offset",
          dy: 0,
        },
        0
      );
      pressAnim.add(
        {
          targets: "#ys-wheel-button-blur",
          stdDeviation: 0,
        },
        0
      );
      const onDown = () => {
        pressAnim.play();
      };
      const onUp = () => {
        pressAnim.pause();
        pressAnim.seek(0);
      };
      button.addEventListener("pointerdown", onDown);
      button.addEventListener("pointerup", onUp);
      button.addEventListener("pointercancel", onUp);
      return () => {
        button.removeEventListener("pointerdown", onDown);
        button.removeEventListener("pointerup", onUp);
        button.removeEventListener("pointercancel", onUp);
      };
    }
  }, [buttonRef]);

  return (
    <StyledWrapper
      ref={buttonRef}
      className={className}
      backgroundColor={branding.wheelMainColor}
    >
      <YSLogo color={"white"} size={66} />
    </StyledWrapper>
  );
};

export default Button;
